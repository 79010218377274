<template>
  <div class="relative flex-1">
    <div class="w-full bg-blue-400 text-white p-2 font-medium text-lg">
      Select Your brand
    </div>
    <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">
      Most chosen
    </div>
    <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm">
      <div v-for="(value, key) in mostMakes" v-bind:key="key" class="w-1/4">
        <div class="ml-2 mt-2 px-4 py-2 rounded-lg border-2 border-gray-200 text-gray-600 hover:bg-blue-100 hover:border-blue-600 font-bold hover:text-blue-600 cursor-pointer" v-on:click="select(key)">{{ key }}</div>
      </div>
    </div>
    <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">
      Others
    </div>
    <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm">
      <div v-for="(value, key) in makes" v-bind:key="key" class="w-1/4">
        <div class="ml-2 mt-2 px-4 py-2 rounded-lg border-2 border-gray-200 text-gray-600 hover:bg-blue-100 hover:border-blue-600 font-bold hover:text-blue-600 cursor-pointer" v-on:click="select(key)">{{ key }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  name: "Make",
  data: function () {
    return {
      allMakes: {},
      makes: {},
      mostMakes: {},
      items: []
    }
  },
  methods: {
    select: function (make) {
      this.$store.commit('tradein/setMakes', this.allMakes[make]);
      this.$store.commit('tradein/setPart', 'select-registration');
    },
  },
  created: function() {
    axios.get("/manager/fleet/eurotax/make/").then(response => {
      let newMakes = {};
      var items = []
      if(response.status===200) items = response.data;
      this.items = items;
      _.forEach(items, (item) => {
        item.name = item.name.replace(" SUV", "").trim();
        if(!_.has(newMakes, item.name)) newMakes[item.name] = [];
        newMakes[item.name].push(item.id);
      });
      this.allMakes = newMakes;
      this.makes = _.omit(newMakes, this.$store.getters['types/mostChosenMakes']);
      this.mostMakes =_.pick(newMakes, this.$store.getters['types/mostChosenMakes']);
    });
  },
}
</script>

<style scoped>

</style>